import { useContext, useState } from "react";

import { UserContext } from "features/user";

// Create a preview submission from form data
export default function usePreviewSubmission({ formRef, brief = {}, images = [] }) {
  const { user } = useContext(UserContext);
  const [submission, setSubmission] = useState({});

  const handleCreatePreviewSubmission = () => {
    const fd = new FormData(formRef.current);
    const formData = {};
    new Set(fd.keys()).forEach((key) => {
      const value = fd.getAll(key);
      formData[key] = value.length > 1 ? value : value[0];
    });

    formData.id = 0;
    formData.published = false;
    formData.images = images.map(({ order_id: orderId, file_id: fileId, ...image }) => ({
      order_id: orderId,
      image: { original: { id: fileId, ...image } },
    }));
    formData.brief = brief;
    formData.user = user;

    setSubmission(formData);
  };

  const handleClearPreviewSubmission = () => setSubmission({});

  return {
    submission,
    handleClearPreviewSubmission,
    handleCreatePreviewSubmission,
  };
}
